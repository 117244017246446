@import "~bootstrap/scss/bootstrap";
// @import "flex";
@import "~react-image-gallery/styles/css/image-gallery.css";

$Background_for_Rows_Color: #f5f7f8;
$Dark_Titles_Color: #3d4148;
$Small_Light_Grey_Text_Color: #8a8a8a;
$Light_Grey_Status_Color: #e3e3e3;

$Border_Color: #e3e3e3;
$Light_Blue_Color: #b6d2f2;
$Light_Red_Color: #f1cece;
$Dark_Red_Color: #aa0707;
._loading_overlay_wrapper {
    height: 100vh;
    width: 100vw;
    ._loading_overlay_overlay {
        z-index: 111111 !important;
        height: 100%;
        width: 100%;
        .css-42igfv {
            position: fixed !important;
            left: 50%;
            top: 45%;
            @media (max-width: 767px) {
                left: 40%;
                top: 50%;
            }
        }
    }
}

.main_container {
    .styles_scroll-to-top__2A70v {
        /* border-radius: 5%; */
        background-color: #ececec;
        right: 20px;
        bottom: 40px;
        position: fixed;
        z-index: 2;
        cursor: pointer;
        border-radius: 5px;
        width: 52px;
        height: 55px;
        transition: opacity 1s ease-in-out;
        box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 30px;
        }
    }
    .navbar.navbar-expand.navbar-light.bg-light {
        padding: 10px 20px 5px;
        background-color: #f2f4f4 !important;

        .container {
            padding: 0px;
            display: flex;
            flex-direction: column;
            flex-wrap: inherit;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            .navbar-brand {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                margin: 0px;
                gap: 10%;
                img {
                    max-width: 60%;
                }
                .basket_button {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 40%;
                    img {
                        z-index: 1220;
                        position: fixed;
                        top: 0;
                        margin: 18px 10px 0px;
                        height: 22px;
                        width: 22px;
                    }
                }
            }
        }
    }
    .navbar_container {
        background-color: #f2f4f4 !important;
        height: 45px;
        padding: 5px 20px;
        .nav {
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            gap: 15px;
            // padding: 10px 25px;
            height: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            .nav-link {
                font-size: 13px;
                background: white;
                white-space: nowrap;
                font-weight: 500;
                border-radius: 57px;
                padding: 6px 10px;
                color: #808b96;
            }
            .nav-link.active {
                background: #2e4053;
                color: white;
                font-weight: 700;
            }
        }
    }

    .content {
        // padding: 8px;
        .search_components_container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            z-index: 100;
            // align-items: center;
            padding: 15px 20px 0px;
            position: sticky;
            top: 0;
            // background: white;
            // border-bottom: 1px solid grey;
            // margin: 0px 0px 10px 0px;
            background-color: #f2f4f4 !important;
            .showcode_container {
                display: flex;
                align-items: center;
                justify-content: space-around;
                .show-code {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 5px;
                    input[type="checkbox"] {
                        height: 14px;
                        width: 14px;
                        border-color: #808b96;
                    }
                    label {
                        margin: 0;
                        font-size: 14px;
                        color: #808b96;
                    }
                }
            }
            .serial_search_container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 15px;
                .form_field {
                    max-height: 28px;
                    width: 80%;
                    h2 {
                        display: none;
                    }
                    input,
                    .css-ee3byn-Select {
                        height: 28px !important;
                        min-height: 28px !important;
                        width: 100%;
                        line-height: 1.5;

                        padding: 8px 12px;
                        border: 1.5px solid #e2e2e2;
                        background-color: #fafafa;
                        font-size: 0.9rem;
                        outline: #e2e2e2;
                        height: 100%;
                        padding-right: 36px;
                        padding-right: 36px;
                        padding-right: 66px;
                    }
                    .css-1k0hmue-IconWrapper svg.search-icon {
                        fill: #34495e;
                    }
                    > ul {
                        list-style-type: none !important;
                        display: block !important;
                        padding: 5px 0px;
                        margin: 0;
                        li {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            input {
                                height: 18px;
                                width: 18px;
                            }
                            span {
                                // height: 18px;
                            }
                        }
                        li:nth-child(2) {
                            display: none;
                        }
                    }
                }
                img {
                }
                button {
                    width: 20%;
                    border: none;
                    cursor: pointer;
                }
            }
            .filters {
                opacity: 0;
                // visibility: hidden;
                height: 0px;
                pointer-events: none;
                cursor: default;
            }
            .show_filters {
                opacity: 1;
                // visibility: visible;
                z-index: 1230;
                height: 100vh;
                position: fixed;
                overflow: scroll;
                width: 100%;
                top: 0;
                // bottom: 0;
                left: 0;
                background: white;
                padding: 0px 20px;
                overscroll-behavior: contain;
                .filter_header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #34495e !important;
                    background-color: white;
                    column-gap: 3px;
                    padding: 10px 0px;
                    position: sticky;
                    top: 0;
                    z-index: 10;
                    h4 {
                        font-size: 16px;
                        width: 50%;
                        text-align: left;
                        margin: 0;
                    }
                    span,
                    span button {
                        width: 25%;
                        font-size: 12px !important;
                        text-decoration: underline !important;
                        button {
                            width: auto;
                            padding: 0;
                        }
                    }
                    button {
                        width: 20%;
                        border: none;
                        background: inherit;
                        font-size: 15px;
                        font-weight: 600;
                        background: #34495e;
                        color: #f2f4f4;
                    }
                }
                .accordion {
                    border-top: 1px solid #d0d3d4 !important;
                    .accordion-item {
                        border-color: white;
                        background-color: #f2f4f5 !important;
                        border: white;
                        .accordion-header {
                            .accordion-button,
                            .accordion-button:focus {
                                padding: 18px 0px;
                                font-size: 14px;
                                font-weight: 700;
                                color: #34495e !important;
                                outline: none !important;
                                background: white !important;
                                border: none !important ;
                                box-shadow: none !important;
                                border-bottom: 1px solid #d0d3d4 !important;
                                // border-top: 1px solid black !important;
                            }
                            .accordion-button::after {
                                content: "+";
                                /* width: 12px; */
                                /* height: 12px; */
                                background-image: none;
                                transition: none !important;
                                transform: none !important;
                                position: relative;
                                color: #34495e;
                                padding: 0px 5px;
                                outline: none;
                                background: #f2f4f4;
                            }
                            .accordion-button:not(.collapsed)::after {
                                content: "-";
                                padding: 0px 7px;
                            }
                        }
                        .accordion-body {
                            // height: 200px;
                            padding: 10px 0px;
                            background-color: white !important;
                            display: flex;
                            flex-direction: column;
                            row-gap: 10px;
                            .form_field {
                                // max-height: 34px;
                                // width: 80%;
                                h2 {
                                    font-size: 14px;
                                    color: #34495e !important;
                                    font-weight: 500;
                                    margin: 10px 0px;
                                    text-align: left;
                                }
                                input,
                                .css-ee3byn-Select {
                                    height: 28px !important;
                                    min-height: 28px !important;
                                    width: 100%;
                                    line-height: 1.5;

                                    padding: 8px 12px;
                                    border: 1.5px solid #e2e2e2;
                                    background-color: #fafafa;
                                    font-size: 0.9rem;
                                    outline: #e2e2e2;
                                    height: 100%;
                                    padding-right: 36px;
                                    padding-right: 36px;
                                    padding-right: 66px;
                                }
                                .css-1k0hmue-IconWrapper svg.search-icon {
                                    fill: #34495e;
                                }
                                > ul {
                                    list-style-type: none !important;
                                    display: block !important;
                                    padding: 5px 0px;
                                    margin: 0;
                                    li {
                                        display: flex;
                                        align-items: center;
                                        gap: 6px;
                                        max-height: 14px;
                                        input {
                                            height: 14px !important;
                                            width: 14px;
                                        }
                                        span {
                                            // height: 18px;
                                            font-size: 13px;
                                            margin: 0px 0px 0px 0px;
                                            color: #34495e;
                                            font-weight: 500;
                                        }
                                    }
                                    li:first-child {
                                        display: none;
                                    }
                                }
                                .css-3cr1qg-suggestionsContainer,
                                .css-cfyl0p-InputWrapper.eiy7zyl0 {
                                    button,
                                    input {
                                        height: 32px;
                                        min-height: 32px !important;
                                        font-size: 13px;
                                        background: white !important;
                                        color: #6c6c6c;
                                        padding: 8px !important;
                                    }
                                    .filter_options_container {
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 5px;
                                        height: 100px;
                                        overflow-y: scroll;
                                        .filter_options,
                                        .filter_options_active {
                                            width: fit-content;
                                            font-size: 11px;
                                            border: 0.5px solid #eeeeee;
                                            padding: 5px;
                                            border-radius: 3px;
                                            background-color: #eeeeee;
                                            cursor: pointer;
                                        }
                                        .filter_options_active {
                                            border: 0.5px solid black !important;
                                        }
                                    }
                                    .subtype_filter_options_container {
                                    }
                                    ul {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: wrap;
                                        justify-content: flex-start;
                                        gap: 10px;
                                        width: 100%;
                                        /* height: 100px; */
                                        // border: 1px solid #ccc;
                                        border: none;
                                        background-color: #fff;
                                        font-size: 0.9rem;
                                        z-index: 3;
                                        position: absolute;
                                        margin: 0;
                                        padding: 0px 0px 5px;
                                        list-style: none;
                                        max-height: 160px;
                                        // min-height: 175px;
                                        overflow-y: auto;
                                        input {
                                            font-size: 13px;
                                            position: sticky;
                                            top: 0;
                                            z-index: 4;
                                            width: 100%;
                                            margin: 3px 3px;
                                        }
                                        li {
                                            width: fit-content;
                                            font-size: 12px;
                                            border: 0.5px solid #f8f8f8;
                                            padding: 5px;
                                            border-radius: 3px;
                                            background-color: #f8f8f8 !important;
                                            cursor: pointer;
                                            height: fit-content;
                                            display: flex;
                                            gap: 6px;
                                            color: #34495e;
                                            font-weight: 500;
                                            .css-1yjrvfv-Tick {
                                                padding: 0px 4px;
                                            }
                                            .css-1yjrvfv-Tick::after {
                                                content: "x";
                                                /* width: 12px; */
                                                /* height: 12px; */
                                                background-image: none;
                                                // transition: none !important;
                                                // transform: none !important;
                                                position: relative;
                                                color: #34495e;
                                                // padding: 0px 4px;
                                                outline: none;
                                                // background: #f2f4f4;

                                                // box-sizing: content-box;
                                                // content: "";
                                                // position: absolute;
                                                // background-color: transparent;
                                                top: -9px;
                                                font-size: 20px;
                                                /* left: 0; */
                                                /* width: 8px; */
                                                /* height: 4px; */
                                                /* margin-top: -4px; */
                                                border: none;
                                                /* border-color: #0B6AFF; */
                                                /* border-width: 0 0 2px 2px; */
                                                /* border-radius: 0; */
                                                /* border-image: none; */
                                                -webkit-transform: rotate(-45deg) scale(1);
                                                -ms-transform: rotate(-45deg) scale(1);
                                                transform: rotate(-45deg) scale(1);
                                                -webkit-transition: all 200ms ease-out;
                                                transition: all 200ms ease-out;
                                            }
                                        }
                                        li.active {
                                            background-color: #34495e !important;
                                            color: white;
                                            .css-1yjrvfv-Tick::after {
                                                color: white;
                                            }
                                        }
                                    }
                                }
                            }
                            .price-range {
                                margin-bottom: 8px;
                                .price-range__input {
                                    display: flex;
                                    align-items: baseline;
                                    flex-direction: column;
                                    > div {
                                        width: 100%;
                                        > div {
                                            > input {
                                                background: white !important;
                                                color: #6c6c6c;
                                                padding: 5px;
                                                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                                                    height: 30px;
                                                }
                                                height: 30px !important;
                                                min-height: 30px !important;
                                                font-size: 0.8rem;
                                                border: 1.5px solid #e2e2e2;
                                            }
                                        }
                                        > h2 {
                                            font-size: 14px;
                                            color: #34495e !important;
                                            font-weight: 500;
                                            line-height: 20px;
                                            display: block;
                                            max-width: 100%;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            text-align: left;
                                        }
                                    }
                                    .price-range__input--price-input {
                                        width: 100%;
                                        height: 30px;
                                    }
                                }
                            }
                            .carat-range-input {
                                margin-bottom: 8px;
                                div > div > input {
                                    background: white !important;
                                    color: #6c6c6c;
                                    padding: 5px;
                                    height: 30px !important;
                                    min-height: 30px !important;
                                    font-size: 0.8rem;
                                    border: 1.5px solid #e2e2e2;
                                }
                                display: flex;
                                flex-direction: column;
                                .carat-range-input__carat-slider {
                                    display: flex;
                                    align-items: center;
                                    vertical-align: middle;
                                    width: 100%;
                                    h2 {
                                        font-size: 14px;
                                        // font-family: "Proxima Nova Regular" !important;
                                        line-height: 30px;
                                        font-size: 14px;
                                        color: #34495e !important;
                                        font-weight: 500;
                                        margin: 0px;
                                        display: block;
                                        max-width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                                .carat-range-input__carat-input {
                                    width: 100%;
                                    height: 30px;
                                }
                            }
                            // .date-range {
                            .date-range-input {
                                display: flex;
                                flex-direction: column;
                                h2 {
                                    // font-family: "Proxima Nova Regular" !important;
                                    line-height: 30px;
                                    font-size: 14px !important;
                                    color: #34495e !important;
                                    font-weight: 500;
                                    margin: 0px;
                                    display: block;
                                    max-width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    width: 100%;
                                    text-align: left;
                                }
                                .css-ln922y-Flex,
                                .css-ylmnjo {
                                    width: 100%;
                                    height: 30px;
                                    display: flex;
                                    flex-direction: row;
                                    div.css-1j6xlwo,
                                    div.css-oced0z-Flex-leftLabel-Flex,
                                    div.css-u4hgrv-Flex-leftLabel-Flex {
                                        width: 40%;
                                        border: 1px solid #ccc;
                                        background: white !important;
                                        border: none;

                                        .DayPickerInput {
                                            height: 30px !important;
                                            input {
                                                background: white !important;
                                                color: #6c6c6c;
                                                padding: 5px;
                                                height: 30px !important;
                                                min-height: 30px !important;
                                                font-size: 0.8rem;
                                                border: 1.5px solid #e2e2e2;
                                            }

                                            .DayPickerInput-OverlayWrapper {
                                                // visibility: visible;
                                                display: block;
                                                width: 100%;
                                            }
                                        }
                                        svg {
                                            // font-size: 11px;
                                            height: 7px;
                                            flex-basis: 20%;
                                        }
                                    }
                                    .css-w7gaf3,
                                    .css-1llwu9v-Flex-Flex-Flex-Flex,
                                    .css-sdo7u7-Flex-Flex-Flex-Flex {
                                        flex-basis: 20%;
                                    }
                                }
                            }
                            .diamond-range {
                                margin-bottom: 8px;
                                .e1i1ftc40 {
                                    display: none;
                                }
                                .diamond-range-input {
                                    display: none;
                                    > div {
                                        width: 30%;
                                        > div {
                                            > input {
                                                background: #fff;
                                                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                                                    height: 30px;
                                                }
                                            }
                                        }

                                        > h2 {
                                            font-size: 14px;
                                            color: #58585a;
                                            line-height: 26px;
                                            margin: 0px;
                                        }
                                    }

                                    span {
                                        margin: 2px 0 0;
                                        text-align: center;
                                        display: inline-block;
                                        width: 45px;
                                    }
                                    .diamond-input-container {
                                        width: 73%;
                                        height: 30px;
                                        @media screen and (min-width: 1330px) and (max-width: 1430px) {
                                            width: 73%;
                                        }
                                        @media screen and (min-width: 1100px) and (max-width: 1320px) {
                                            width: 73%;
                                        }
                                    }
                                }
                                .diamond-con-range-input {
                                    width: 100%;
                                    div > div > input {
                                        background: #fff;
                                    }
                                    display: inline-block;
                                    .diamond-slider-container {
                                        display: flex;
                                        flex-direction: column;
                                        width: 100%;
                                        h2 {
                                            line-height: 30px;
                                            font-size: 14px !important;
                                            color: #34495e !important;
                                            font-weight: 500;
                                            margin: 0px;
                                            display: block;
                                            max-width: 100%;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            width: 100%;
                                            text-align: left;
                                        }
                                    }
                                    .diamond-input-container {
                                        width: 100%;
                                        height: 30px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: wrap;
                                        div {
                                            // display: inline-block;
                                            width: 40%;
                                            // @media (max-width: 767px) {
                                            //     width: 20%;
                                            // }
                                            // @media (min-width: 768px) and (max-width: 1024px) {
                                            //     width: 34.5%;
                                            // }
                                            // text-align: center;
                                            input {
                                                background: white !important;
                                                color: #6c6c6c;
                                                padding: 5px;
                                                height: 30px !important;
                                                min-height: 30px !important;
                                                font-size: 0.8rem;
                                                border: 1.5px solid #e2e2e2;
                                            }
                                        }
                                        // div:first-child {
                                        //     width: 40%;
                                        //     // @media (max-width: 767px) {
                                        //     //     width: 40%;
                                        //     // }

                                        // }
                                        // div:last-child {
                                        //     width: 32.6%;
                                        //     @media (max-width: 767px) {
                                        //         width: 40%;
                                        //     }
                                        //     input {
                                        //         padding: 5px;
                                        //         height: 30px;
                                        //     }
                                        // }
                                    }
                                }
                                .color-input-dropdowns {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    > h2 {
                                        line-height: 30px;
                                        font-size: 14px !important;
                                        color: #34495e !important;
                                        font-weight: 500;
                                        margin: 0px;
                                        display: block;
                                        max-width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        width: 100%;
                                        text-align: left;
                                    }
                                    > span {
                                        font-size: 14px;
                                        color: #58585a;
                                        width: 20%;
                                        @media (min-width: 768px) and (max-width: 1024px) {
                                            width: 17px;
                                        }
                                        display: flex;
                                        margin: 2px 0 0;
                                        justify-content: center;
                                        flex: 1;
                                    }
                                    .start-range,
                                    .end-range {
                                        width: 40%;
                                        margin-top: 0px;
                                        // @media (max-width: 767px) {
                                        //     width: 28%;

                                        > div {
                                            > button {
                                                background: white !important;
                                                color: #6c6c6c;
                                                padding: 5px;
                                                height: 30px !important;
                                                min-height: 30px !important;
                                                font-size: 0.8rem;
                                                border: 1.5px solid #e2e2e2;
                                                > div {
                                                    width: 100%;
                                                    text-overflow: unset;
                                                }
                                            }
                                            > ul {
                                                top: 30px;
                                                font-size: 11px;
                                                max-height: 220px !important;
                                                li {
                                                    padding: 4px 6px !important;
                                                }
                                            }
                                        }
                                        // }
                                    }
                                }
                            }
                            .hide__multilist {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .selectedFilters {
            margin: 8px 0;
            > a {
                background: #fff;
                font-size: 12px;
                white-space: nowrap !important;
            }
        }
        .selected--filters--container {
            display: flex !important;
            justify-content: flex-start !important;
            align-items: center !important;
            padding: 0px 0px 0px 0px;
            width: 100%;
            max-height: 30px;
            margin: 0px 0px;
            overflow-y: hidden;
            .clear-filter-btn,
            .clear-all-btn {
                font-size: 14px;
                white-space: nowrap !important;
                margin: 0px 4px;
                -webkit-appearance: none;
                border: none;
                border-right: 1px solid;
                outline: none;
                // background: transparent;
                background: #34495e;
                color: #f2f4f4;
                .clear--icon {
                    margin-left: 6px;
                    opacity: 0.7;
                }
            }
            .clear-all-btn {
                border: none !important;
                position: sticky;
                right: 0;
                // background: white;
                white-space: nowrap !important;
                background: #34495e;
                color: #f2f4f4;
            }
        }
        .result_status_view_option_container {
            display: flex;
            width: 100%;
            padding: 15px 20px;
            flex-direction: row;
            align-items: center;
            // background-color: #f8f9f9 !important;

            label {
                width: 70%;
                text-align: left;
                color: #34495e;
                font-weight: 600;
                font-size: 14px;
            }
            .result_view_options {
                display: flex;
                width: 30%;
                gap: 12px;
                padding: 5px;
                align-items: center;
                justify-content: flex-end;
                // margin-top: 8px;
                // border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
                img {
                    height: 16px !important;
                    width: 16px !important;
                }
                img.active {
                    height: 22px !important;
                    width: 22px !important;
                    // border: 1px solid grey;
                    // border-radius: 5px;
                    // padding: 2px;
                }
            }
        }
        .es_results {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 10px 20px 0px;
            align-items: flex-end;
            gap: 10px;
            > button,
            select {
                width: fit-content;
                border: grey;
                border-radius: 5px;
                outline: none;
                padding: 4px 10px;
            }
            > select {
                border: solid 1px;
            }

            // .List_result_container,
            .Grid_result_container,
            .Grid3_result_container,
            .Grid2_result_container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // margin-top: 10px;
                // padding: 5px;
                gap: 10px;

                .card {
                    height: 330px;
                    width: 100%;
                    padding: 15px;
                    gap: 10px;
                    // font-family: "Circular-Loom" !important;
                    border-radius: 0px;
                    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    // background-color: rgba(241, 241, 241, 0.876);
                    .card-title.h5 {
                        font-size: 15px;
                        display: flex;
                        flex-direction: row !important;
                        gap: 2px !important;
                        align-items: center;
                        justify-content: center;
                        // align-items: center !important;
                        text-align: left;
                        margin: 0px !important;
                        color: #808b96;
                        font-weight: 800;
                        height: 15%;
                    }

                    .image_container {
                        height: 55%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        position: relative;
                        .item_image {
                            height: 90%;
                            img {
                                max-width: 100%;
                                // max-height: 40%;

                                max-height: 100% !important;
                            }
                        }
                        span {
                            font-size: 14px;
                            color: #34495e;
                            // position: absolute;
                            // bottom: -10%;
                            height: 10%;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                    }
                    .card-body {
                        padding: 5px 0 0;
                        height: 25%;
                        color: #34495e;

                        // .card-title.h5 {
                        //     font-size: 1rem;
                        //     display: flex;
                        //     flex-direction: column;
                        //     gap: 3px;
                        //     align-items: center;
                        //     // text-align: left;
                        // }
                        .card-text {
                            // text-align: left;
                            padding: 0px 0px 0px;
                            display: flex;
                            flex-direction: column;
                            row-gap: 5px;
                            font-size: 13px;
                            .item_description {
                                font-size: 17px;
                                // display: block !important;
                                display: flex !important;
                                flex-direction: column;
                                font-weight: 500;
                                line-height: 1.5em;
                                height: 3em;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .item_price {
                                font-size: 19px;
                                font-weight: 800;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 5px;
                            }
                        }
                    }
                    .result_action_group {
                        // display: flex !important;
                        display: flex !important;
                        justify-content: flex-end;
                        align-items: center;
                        button {
                            border: none;
                            width: fit-content;
                            height: fit-content;
                            padding: 5px;
                            margin: auto;
                            background-color: #f2f4f4 !important;
                            position: absolute;
                            // top: 50%;
                            // right: 2%;
                            top: 45%;
                            transform: translateY(-45%);
                            right: 2%;

                            // width: 100%;
                            img {
                                height: 28px;
                                width: 28px;
                            }
                        }
                    }
                }
            }

            .Grid3_result_container,
            .Grid2_result_container {
                display: flex;
                flex-direction: row !important;
                flex-wrap: wrap;
                justify-content: space-between !important;
                gap: 6px !important;
                .card {
                    width: 31% !important;
                    height: 160px !important;
                    padding: 5px !important;
                    gap: 10px !important;
                    // justify-content: space-between;
                    .card-title.h5 {
                        height: 20% !important;
                        font-size: 8px !important;
                        font-weight: 900 !important;
                        flex-direction: column !important;
                        // flex-wrap: wrap !important;
                        column-gap: 2px !important;
                        row-gap: 0px !important;
                        padding: 0px 0px 0px;
                        span {
                            text-align: center;
                        }
                    }
                    .image_container {
                        height: 65% !important;
                        flex-direction: column !important;
                        align-items: center;
                        .item_image {
                            height: 90%;
                            img {
                                max-width: 100% !important;
                                max-height: 100% !important;
                            }
                        }
                        span {
                            height: 10%;
                            bottom: 0;
                            font-size: 11px;
                            position: relative;
                            text-transform: uppercase;
                        }
                    }
                    .card-body {
                        height: 15%;
                        padding: 0px !important;

                        .card-text {
                            div.item_price {
                                display: flex !important;
                                align-items: center;
                                justify-content: center;
                                font-size: 9px !important;
                                gap: 3px;
                            }
                        }
                    }
                    .result_action_group {
                        button {
                            // top: 50%;
                            right: 2%;
                            top: 45% !important;
                            transform: translateY(-45%);
                            padding: 1px 3px !important;
                            img {
                                height: 18px !important;
                                width: 18px !important;
                            }
                        }
                    }
                }
            }
            .Grid2_result_container {
                // justify-content: space-between !important;
                // padding: 10px 20px !important;
                gap: 10px !important;
                .card {
                    width: 48% !important;
                    height: 260px !important;
                    margin: 0px !important;
                    padding: 10px !important;
                    // gap: 8px !important;
                    justify-content: space-between !important;
                    .card-title.h5 {
                        height: 15% !important;
                        font-size: 12px !important;
                        font-weight: 900 !important;
                        // flex-wrap: wrap !important;
                        column-gap: 0px !important;
                        row-gap: 0px !important;
                        align-items: center;
                        padding: 0px 0px 0px;
                        flex-direction: column !important;
                    }
                    .image_container {
                        height: 55% !important;
                        flex-direction: column !important;
                        align-items: center;
                        position: relative;
                        .item_image {
                            height: 90%;
                            img {
                                max-width: 100% !important;
                                max-height: 100% !important ;
                                // margin: auto;
                            }
                        }
                        span {
                            position: relative !important;
                            text-transform: uppercase;
                            font-size: 13px;
                            // bottom: 0;
                            height: 10%;
                            color: #34495e;
                            font-weight: 700;
                        }
                    }
                    .card-body {
                        height: 30% !important;
                        .card-text {
                            // display: block !important;
                            div.item_description,
                            div {
                                // display: block !important;
                                font-size: 13px !important;
                                display: flex !important;
                                flex-direction: column;
                            }
                            div.item_price {
                                display: flex !important;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                font-size: 13px !important;
                                padding: 0px 0px 0px;
                            }
                            > div {
                                display: none !important;
                            }
                        }
                    }
                    .result_action_group {
                        button {
                            // top: 2% !important;

                            top: 45% !important;
                            transform: translateY(-45%);
                            right: 2% !important;

                            img {
                                height: 22px !important;
                                width: 22px !important;
                            }
                        }
                    }
                }
            }
            .List_result_container {
                width: 100%;
                padding: 0px !important;
                gap: 6px !important;
                .card {
                    width: 100% !important;
                    height: 135px !important;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 15px 0px 15px !important;
                    margin: 0px !important;
                    gap: 7px !important;
                    box-shadow: none !important;
                    border: none !important;
                    border-radius: 0px !important ;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;

                    .item_no {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        font-weight: 600;
                        // color: #808b96;
                        color: #34495e;
                    }
                    .image_container {
                        width: 25%;
                        height: 100% !important;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        // row-gap: 10px;
                        .item_image {
                            height: 90%;
                            img {
                                max-width: 100% !important;
                                margin: auto;
                                // border: none !important;
                                max-height: 100% !important;
                            }
                        }
                        span {
                            height: 10%;
                            padding: 2px 10px;
                            // position: absolute;
                            // bottom: 3%;
                            // left: 7%;
                            // background: #34495e;
                            color: #34495e;
                            font-weight: 500;
                            // color: #f2f4f4;
                            font-size: 12px;
                            text-transform: uppercase;
                        }
                    }
                    .card-body {
                        width: 55%;
                        height: 100% !important;
                        padding: 0px !important;
                        .card-title.h5 {
                            font-size: 13px !important;
                            display: flex;
                            flex-direction: row !important;
                            justify-content: flex-start !important;
                            gap: 2px !important;
                            align-items: center;
                            text-align: left;
                            margin: 0px !important;
                            color: #808b96;
                            font-weight: 700;
                            span {
                                white-space: nowrap;
                            }
                        }
                        .card-text {
                            // text-align: left;
                            padding: 4px 0px;
                            display: flex;
                            flex-direction: column;
                            row-gap: 15px !important;
                            font-size: 13px;
                            text-align: left;
                            color: #34495e;

                            .item_description {
                                font-size: 13px !important;
                                // display: block !important;
                                font-weight: 500;
                                line-height: 1.5em;
                                height: 3em;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: flex !important;
                                flex-direction: column;

                                span:first-child {
                                    // width: 100%;
                                }
                                // white-space: nowrap;
                            }

                            .item_price {
                                display: block !important;

                                font-size: 16px;
                                font-weight: 700;
                            }
                            > div {
                                display: none !important;
                            }
                        }
                    }
                    .result_action_group {
                        // display: flex !important;
                        // justify-content: flex-end;
                        // align-items: flex-end;
                        display: grid !important;
                        position: relative !important;
                        width: 7%;
                        height: 100% !important;
                        button {
                            border: none;
                            width: fit-content;
                            height: fit-content;
                            padding: 7px 10px;
                            margin: auto;
                            background-color: #f2f4f4 !important;
                            position: absolute;
                            right: -10%;
                            top: 40%;

                            img {
                                // position: absolute !important;
                                // top: 0;
                                // left: 0;
                                // margin: auto;
                                height: 28px !important;
                                width: 28px !important;
                            }
                        }
                    }
                }
            }
        }
        .banner {
            opacity: 1;
            max-width: 1275px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            > img {
                @media (max-width: 767px) {
                    max-width: 100%;
                }
            }
        }
    }

    .es-scroll-button {
        .ScrollUpButton__Container {
            border: 5px solid lightgrey !important;
            // margin-right: 12px;
            > svg {
                fill: lightgrey;
            }
        }
    }
}
.single_item_modal {
    z-index: 1250 !important;
    height: 100vh;
    .modal-dialog {
        margin: 0;
        height: 100%;
        .modal-content {
            height: 100%;
            background-color: #f2f4f4 !important;
            border: none !important;
            border-radius: 0px;
            .modal-header {
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .btn-close {
                    position: relative;
                    font-size: 12px;
                    padding: 15px;
                    position: fixed;
                    top: 50%;
                    background-color: #efefef;
                    z-index: 1;
                    margin: 0;
                    background: "transparent url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e) center/1.5em auto no-repeat" !important;
                    opacity: 1;
                    background-position: center;
                }
            }
            .modal-body {
                padding: 10px 0px;
                height: 100%;
                img.basket {
                    z-index: 1220;
                    position: fixed;
                    top: 0;
                    right: 0;
                    margin: 5px;
                    height: 22px;
                    width: 22px;
                }

                .single_item_container {
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    padding: 0px 0px 10px;
                    background: white !important;
                    // background-color: #f2f4f4 !important;
                    font-family: "Circular-Loom" !important;
                    height: 93%;
                    overflow: scroll;
                    .item_header_options {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        padding: 0px 10px;
                        gap: 5px;

                        img {
                            width: 5%;
                        }
                        button {
                            width: 5%;
                            border: none;
                            outline: none;
                            font-size: 22px;
                            background-color: inherit;
                        }
                    }

                    .item_short_detail {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-items: center;
                        gap: 6px;
                        color: #34495e;
                        padding: 10px 25px;
                        background-color: #f2f4f4 !important;

                        .image_container {
                            width: 100%;
                            padding: 0px 0px 0px;
                            min-height: 40vh;
                            // background: white;
                            margin: 0px 0px 5px 0px;
                            // img {
                            //     width: 80%;
                            // }
                            // @media (min-width: 768px) {
                            //     width: 70%;
                            //     margin: auto;
                            // }
                            .image-gallery-slides {
                                .image-gallery-image,
                                iframe,
                                video {
                                    max-height: 40vh;
                                    min-height: 40vh;
                                    width: 100%;
                                    // @media (min-width: 768px) {
                                    //     max-height: 60vh;
                                    // }
                                }
                                iframe,
                                video {
                                    // @media (min-width: 768px) {
                                    //     min-height: 60vh;
                                    // }
                                }
                            }
                            .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
                                padding: 10px;
                                // @media (min-width: 1080px) {
                                //     width: 70%;
                                //     margin: auto;
                                // }
                                .image-gallery-thumbnails {
                                    display: grid;
                                    .image-gallery-thumbnails-container {
                                        float: left;
                                        .image-gallery-thumbnail {
                                            width: 52px;
                                            height: 52px;
                                            margin: 0px 4px;
                                            @media (min-width: 768px) {
                                                width: 82px;
                                                height: 82px;
                                            }
                                            @media (min-width: 1440px) {
                                                width: 86px;
                                                height: 86px;
                                            }
                                            outline: none;
                                            border: 1px solid #acacac;
                                        }
                                        .image-gallery-thumbnail.active,
                                        .image-gallery-thumbnail:focus,
                                        .image-gallery-thumbnail:hover {
                                            outline: none;
                                            border: 1px solid #1c3279;
                                        }
                                    }
                                }
                            }
                        }
                        span {
                            width: 100%;
                            font-size: 15px;
                            font-weight: 800;
                            text-transform: uppercase;
                            text-align: center;
                        }
                        h6 {
                            width: 100%;
                            font-size: 16px;
                            text-align: left;
                            margin: 0px !important;
                            color: #808b96 !important;
                            font-weight: 400;
                            // height: 15px;
                        }
                        .single_item_description {
                            text-align: left;
                            font-size: 17px;
                            width: 100%;
                        }
                        .single_item_price {
                            width: 100%;
                            // margin: 10px 0px 25px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            grid-column-gap: 5px;
                            column-gap: 10px;

                            label {
                                font-size: 26px;
                                // color: #31406f;
                                // font-family: serif;
                                font-weight: 900;
                            }
                        }
                    }
                    .single_item_details {
                        // display: flex;
                        // flex-direction: column;
                        .accordion {
                            padding: 0px 20px 10px;
                            background: white;
                            color: #34495e;
                            .accordion-item {
                                border: none;
                                // border-bottom: 1px solid #d0d3d4;
                                .accordion-header {
                                    .accordion-button,
                                    .accordion-button:focus {
                                        padding: 15px 0px;
                                        font-size: 14px;
                                        font-weight: 700;
                                        color: #34495e !important;
                                        outline: none !important;
                                        background: white !important;
                                        border: none !important ;
                                        box-shadow: none !important;
                                        border-bottom: 1px solid #d0d3d4 !important;
                                        border-radius: 0px;
                                        // border-top: 1px solid black !important;
                                    }
                                    .accordion-button::after {
                                        content: "+";
                                        background-image: none;
                                        transition: none !important;
                                        transform: none !important;
                                        position: relative;
                                        color: #34495e;
                                        padding: 0px 6px;
                                        outline: none;
                                        background: #f2f4f4;
                                    }
                                    .accordion-button:not(.collapsed)::after {
                                        content: "-";
                                        padding: 0px 7px;
                                    }
                                    .accordion-button:hover {
                                        z-index: 0 !important;
                                    }
                                }
                                .accordion-body {
                                    padding: 10px 0px;
                                    h6 {
                                        text-align: left;
                                        padding: 0 0.5rem;
                                    }
                                    table {
                                        // max-height: 266px !important;
                                        // width: 100%;
                                        // height: 100%;
                                        display: block;
                                        margin: 0;
                                        overflow: hidden;
                                        tbody {
                                            display: block;

                                            tr {
                                                display: flex;
                                                gap: 5px;
                                                border: white !important;
                                                td {
                                                    display: block;
                                                    text-align: left;
                                                    font-size: 14px;
                                                    padding: 5px 0px;
                                                    white-space: wrap;
                                                    color: #34495e !important;
                                                    li::marker {
                                                        color: #808b96 !important;
                                                    }
                                                }
                                                td:first-child {
                                                    width: 55%;
                                                    white-space: nowrap;
                                                }
                                                td:nth-child(2) {
                                                    width: 45%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .add_to_basket {
                        background-color: #f2f4f4 !important;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: fixed;
                        bottom: 0;
                        width: 100%;
                        padding: 15px 10px;
                        button {
                            padding: 0;
                            background: white;
                            border: 1px solid #e5e8e8;
                            img {
                                height: 18px;
                                width: 24px;
                            }
                        }
                        button:first-child,
                        button:nth-child(2) {
                            width: 15%;
                            height: 38px;
                        }
                        button:last-child {
                            width: 60%;
                            height: 38px;
                            background: #34495e;
                            color: #f2f4f4;
                            font-weight: bold;
                            img {
                                width: 14px !important;
                                margin: 0px 10px 0px 0px;
                            }
                        }

                        // padding: 10px 25px;
                        // button {
                        //     background: #34495e;
                        //     width: 100%;
                        //     height: 40px;
                        //     color: white;
                        //     border: none;
                        //     outline: none;
                        //     font-size: 18px;
                        //     font-weight: 600;
                        // }
                    }
                }
            }
        }
    }
}

.single_item_modal ~ .modal-backdrop {
    z-index: 1210 !important;
}

.offcanvas-backdrop,
.modal-backdrop {
    background-color: #082157 !important;
}
.modal-backdrop {
    z-index: 1200 !important;
}

.basket_offcanvas {
    height: 100vh !important;
    z-index: 1250 !important;
    .offcanvas-header {
        justify-content: flex-start;
        padding: 15px 20px 5px;
        background: #f2f4f4;

        .offcanvas-title.h5 {
            width: 95%;
            font-size: 18px;
            text-align: center;
            padding: 0px 0px 0px 10%;
        }
        .btn-close {
            position: absolute;
            left: 4%;
            padding: 0;
            background: transparent
                url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAWCAYAAAA1vze2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEzEAABMxAatIXmQAAAKvSURBVEhLpZVdSFNhGMf/Z2e6XDTUxqb0iRFCaFGmOSMiISLqJiiIKLsK7IPsom+aTLpoDbzMbYKakhR+5EUQfUDRjd10FRFBZV/DPrDUppY7Z1vP87qzFNO9h/5wOOOc95zf8/+/z3Om/J6MJSEpRVHEOZmUfkTIkjpnlMViQSKewNj4L1itauqqnKQgDODqm9q6cOpiAB8+fYaqSteXGcIRMSDU2o2WG3140v8MN3vvCbCs5l1pvCjY2oVwRw8mJzVs8ZTh4L5diFN0spoTMgVIItjWjeaOXgGoqliHQH0dli5xIZH4T4gRUZAiam4nBzENHgL460+ioMAJXY+nVsppFiQdETkIM4AcVG4shd9LALcTmqaL+2Y0Y05EF9E5xHtAAK54Mzm44q2D25UP3cQ+xON/3aYhxqCJPWAALSpetRIN52tR6HZB0zVxX1aL83LTbS4gDNB1HaG2HrR09vFlcqXQIhUquYsnuKqpImR1u70RK5YVijQESkCo8veRQcQoc4UALE3TMBodQ3Rsgo5xU8f0T086Lt4PfmFDIIwHj/phIauri5bjyKE9sGXbTLvxlK+FPWeBgM3YeKvVipHRKOr9TbhPoBybDTX7d+PMicOpFfLiRAw3MyAsBv0YGYXvaggPHz8VDg/s3YnTx2uQnZ1latINqZe8Xl/qtxBP8kJ7jhi+yOBXvBn4iOcvX1POE6gsKxVFTM9bRv+ceO6IPMci+M7WYvs2D4GT6Oy5i8ZrHeJzzx1nRrOcGGJHdnK0iaqPDH7BwLsIXrx6i5/UHOXrS5CVJe9o3pJ4dvLzHLh84Rh2VFcJF+237iB0vVvcNwY4kzL65uhyHQ74zh1F9dYKmn4nKjaUiGGVdTKru+YS/+UODQ3j2/dhrCkuEnBZSUNY3M7swAwAAP4ArMtXvn4YAycAAAAASUVORK5CYIJMSmPEzS9biJE72zpJS2klLaWVtJRW0lLaAH4BdxRazz+gNggAAAAASUVORK5CYII=)
                center/22px auto no-repeat !important;
            width: 10%;
            height: 4%;
            opacity: 1;
            border: none;
            border-radius: 0px;
        }
        img {
            width: 7%;
            height: 9px;
        }
    }

    .offcanvas-body {
        padding: 0px;
        .basket_container {
            height: 100%;
            .basket_no_container {
                padding: 5px 20px;
                display: flex;
                background: #f2f4f4;
                color: #34495e !important;
                height: 7%;
                span {
                    width: 90%;
                    font-size: 16px;
                    font-weight: 500;
                    // margin-top: 5px;
                }
                button {
                    width: 28%;
                    border: none;
                    background: inherit;
                    font-size: 12px;
                    display: flex;
                    align-items: flex-start;
                    column-gap: 4px;
                    padding: 0;
                    justify-content: flex-end;
                    img {
                        margin: 3px 2px 2px;
                    }
                }
            }
            .quick__add__form {
                padding: 5px 20px 5px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #e5e8e8 !important;
                background: #f2f4f4;
                select {
                    font-size: 13px;
                    height: 30px;
                    width: 30%;
                    // border: 1px solid #e5e8e8;
                    background: #34495e;
                    color: #f2f4f4;
                }
                .input__button__wrapper {
                    display: flex;
                    width: 67%;
                    height: 30px;
                    justify-content: space-between;
                    .basket__input.quickadd__input.form-group.has-feedback {
                        width: 75%;
                        input {
                            height: 30px;
                            border-radius: 0;
                            padding: 4px;
                            border: 1px solid #e5e8e8 !important;
                        }
                        .form-control:focus {
                            border: 1px solid #e5e8e8;
                            box-shadow: none;
                        }
                    }
                    button {
                        width: 20%;
                        border: none;
                        padding: 2px;
                        font-size: 12px;
                        background: #34495e;
                        color: #f2f4f4;
                    }
                }
            }
            .result_status_view_option_container {
                display: flex;
                width: 100%;
                padding: 5px 20px 5px;
                flex-direction: row;
                align-items: center;
                height: 8%;
                // background-color: #f8f9f9 !important;

                label {
                    width: 70%;
                    text-align: left;
                    color: #34495e;
                    font-weight: 600;
                    font-size: 14px;
                }
                .result_view_options {
                    display: flex;
                    width: 30%;
                    gap: 12px;
                    padding: 5px;
                    align-items: center;
                    justify-content: flex-end;
                    // margin-top: 8px;
                    // border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
                    img {
                        height: 16px !important;
                        width: 16px !important;
                    }
                    img.active {
                        height: 22px !important;
                        width: 22px !important;
                        // border: 1px solid grey;
                        // border-radius: 5px;
                        // padding: 2px;
                    }
                }
            }
            .es_basket_results {
                /* display: flex; */
                /* flex-direction: column; */
                /* flex-wrap: wrap; */
                /* justify-content: space-around; */
                padding: 5px 20px 0px;
                /* align-items: flex-end; */
                /* gap: 10px; */
                overflow: scroll;
                height: 70%;
                > button,
                select {
                    width: fit-content;
                    border: grey;
                    border-radius: 5px;
                    outline: none;
                    padding: 4px 10px;
                }
                > select {
                    border: solid 1px;
                }

                // .List_result_container,
                .Grid_result_container,
                .Grid3_result_container,
                .Grid2_result_container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // margin-top: 10px;
                    // padding: 5px;
                    gap: 10px;

                    .card {
                        height: 330px;
                        width: 100%;
                        padding: 15px;
                        gap: 10px;
                        // font-family: "Circular-Loom" !important;
                        border-radius: 0px;
                        // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        // background-color: rgba(241, 241, 241, 0.876);
                        .card-title.h5 {
                            font-size: 15px;
                            display: flex;
                            flex-direction: row !important;
                            gap: 2px !important;
                            align-items: center;
                            justify-content: center;
                            // align-items: center !important;
                            text-align: left;
                            margin: 0px !important;
                            color: #808b96;
                            font-weight: 800;
                            height: 15%;
                        }

                        .image_container {
                            height: 55%;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                            width: 100%;
                            position: relative;
                            .item_image {
                                height: 90%;
                                img {
                                    max-width: 100%;
                                    // max-height: 40%;

                                    max-height: 100% !important;
                                }
                            }
                            span {
                                font-size: 14px;
                                color: #34495e;
                                // position: absolute;
                                // bottom: -10%;
                                height: 10%;
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                        }
                        .card-body {
                            padding: 5px 0 0;
                            height: 25%;
                            color: #34495e;

                            // .card-title.h5 {
                            //     font-size: 1rem;
                            //     display: flex;
                            //     flex-direction: column;
                            //     gap: 3px;
                            //     align-items: center;
                            //     // text-align: left;
                            // }
                            .card-text {
                                text-align: center;
                                padding: 0px 0px 0px;
                                display: flex;
                                flex-direction: column;
                                row-gap: 5px;
                                font-size: 13px;
                                .item_description {
                                    font-size: 17px;
                                    // display: block !important;
                                    display: flex !important;
                                    flex-direction: column;
                                    font-weight: 500;
                                    line-height: 1.5em;
                                    height: 3em;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .item_price {
                                    font-size: 19px;
                                    font-weight: 800;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 5px;
                                }
                            }
                        }
                        .result_action_group {
                            // display: flex !important;
                            display: flex !important;
                            justify-content: flex-end;
                            align-items: center;
                            button {
                                border: none;
                                width: fit-content;
                                height: fit-content;
                                padding: 5px;
                                margin: auto;
                                background-color: #f2f4f4 !important;
                                position: absolute;
                                // top: 50%;
                                // right: 2%;
                                top: 45%;
                                transform: translateY(-45%);
                                right: 2%;

                                // width: 100%;
                                img {
                                    height: 28px;
                                    width: 28px;
                                }
                            }
                        }
                    }
                }

                .Grid3_result_container,
                .Grid2_result_container {
                    display: flex;
                    flex-direction: row !important;
                    flex-wrap: wrap;
                    justify-content: space-between !important;
                    gap: 6px !important;
                    .card {
                        width: 31% !important;
                        height: 160px !important;
                        padding: 5px !important;
                        gap: 10px !important;
                        // justify-content: space-between;
                        .card-title.h5 {
                            height: 20% !important;
                            font-size: 8px !important;
                            font-weight: 900 !important;
                            flex-direction: column !important;
                            // flex-wrap: wrap !important;
                            column-gap: 2px !important;
                            row-gap: 0px !important;
                            padding: 0px 0px 0px;
                            span {
                                text-align: center;
                            }
                        }
                        .image_container {
                            height: 65% !important;
                            flex-direction: column !important;
                            align-items: center;
                            .item_image {
                                height: 90%;
                                img {
                                    max-width: 100% !important;
                                    max-height: 100% !important;
                                }
                            }
                            span {
                                height: 10%;
                                bottom: 0;
                                font-size: 11px;
                                position: relative;
                                text-transform: uppercase;
                            }
                        }
                        .card-body {
                            height: 15%;
                            padding: 0px !important;

                            .card-text {
                                text-align: center;
                                div.item_price {
                                    display: flex !important;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 9px !important;
                                    gap: 3px;
                                }
                            }
                        }
                        .result_action_group {
                            button {
                                // top: 50%;
                                right: 2%;
                                top: 45% !important;
                                transform: translateY(-45%);
                                padding: 1px 3px !important;
                                img {
                                    height: 18px !important;
                                    width: 18px !important;
                                }
                            }
                        }
                    }
                }
                .Grid2_result_container {
                    // justify-content: space-between !important;
                    // padding: 10px 20px !important;
                    gap: 10px !important;
                    .card {
                        width: 48% !important;
                        height: 260px !important;
                        margin: 0px !important;
                        padding: 10px !important;
                        // gap: 8px !important;
                        justify-content: space-between !important;
                        .card-title.h5 {
                            height: 15% !important;
                            font-size: 12px !important;
                            font-weight: 900 !important;
                            // flex-wrap: wrap !important;
                            column-gap: 0px !important;
                            row-gap: 0px !important;
                            align-items: center;
                            padding: 0px 0px 0px;
                            flex-direction: column !important;
                        }
                        .image_container {
                            height: 55% !important;
                            flex-direction: column !important;
                            align-items: center;
                            position: relative;
                            .item_image {
                                height: 90%;
                                img {
                                    max-width: 100% !important;
                                    max-height: 100% !important ;
                                    // margin: auto;
                                }
                            }
                            span {
                                position: relative !important;
                                text-transform: uppercase;
                                font-size: 13px;
                                // bottom: 0;
                                height: 10%;
                                color: #34495e;
                                font-weight: 700;
                            }
                        }
                        .card-body {
                            height: 30% !important;
                            .card-text {
                                // display: block !important;
                                div.item_description,
                                div {
                                    // display: block !important;
                                    font-size: 13px !important;
                                    display: flex !important;
                                    flex-direction: column;
                                }
                                div.item_price {
                                    display: flex !important;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 13px !important;
                                    padding: 0px 0px 0px;
                                }
                                > div {
                                    display: none !important;
                                }
                            }
                        }
                        .result_action_group {
                            button {
                                // top: 2% !important;

                                top: 45% !important;
                                transform: translateY(-45%);
                                right: 2% !important;

                                img {
                                    height: 22px !important;
                                    width: 22px !important;
                                }
                            }
                        }
                    }
                }
                .List_result_container {
                    width: 100%;
                    padding: 0px !important;
                    gap: 6px !important;
                    .card {
                        width: 100% !important;
                        height: 135px !important;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 15px 0px 15px !important;
                        margin: 0px !important;
                        gap: 7px !important;
                        box-shadow: none !important;
                        border: none !important;
                        border-radius: 0px !important ;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;

                        .item_no {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;
                            font-weight: 600;
                            // color: #808b96;
                            color: #34495e;
                        }
                        .image_container {
                            width: 25%;
                            height: 100% !important;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            // row-gap: 10px;
                            .item_image {
                                height: 90%;
                                img {
                                    max-width: 100% !important;
                                    margin: auto;
                                    // border: none !important;
                                    max-height: 100% !important;
                                }
                            }
                            span {
                                height: 10%;
                                padding: 2px 10px;
                                // position: absolute;
                                // bottom: 3%;
                                // left: 7%;
                                // background: #34495e;
                                color: #34495e;
                                font-weight: 500;
                                // color: #f2f4f4;
                                font-size: 12px;
                                text-transform: uppercase;
                            }
                        }
                        .card-body {
                            width: 55%;
                            height: 100% !important;
                            padding: 0px !important;
                            .card-title.h5 {
                                font-size: 13px !important;
                                display: flex;
                                flex-direction: row !important;
                                justify-content: flex-start !important;
                                gap: 2px !important;
                                align-items: center;
                                text-align: left;
                                margin: 0px !important;
                                color: #808b96;
                                font-weight: 700;
                                span {
                                    white-space: nowrap;
                                }
                            }
                            .card-text {
                                // text-align: left;
                                padding: 4px 0px;
                                display: flex;
                                flex-direction: column;
                                row-gap: 15px !important;
                                font-size: 13px;
                                text-align: left;
                                color: #34495e;

                                .item_description {
                                    font-size: 13px !important;
                                    // display: block !important;
                                    font-weight: 500;
                                    line-height: 1.5em;
                                    height: 3em;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: flex !important;
                                    flex-direction: column;

                                    span:first-child {
                                        // width: 100%;
                                    }
                                    // white-space: nowrap;
                                }

                                .item_price {
                                    display: block !important;

                                    font-size: 16px;
                                    font-weight: 700;
                                }
                                > div {
                                    display: none !important;
                                }
                            }
                        }
                        .result_action_group {
                            // display: flex !important;
                            // justify-content: flex-end;
                            // align-items: flex-end;
                            display: grid !important;
                            position: relative !important;
                            width: 7%;
                            height: 100% !important;
                            button {
                                border: none;
                                width: fit-content;
                                height: fit-content;
                                padding: 7px 10px;
                                margin: auto;
                                background-color: #f2f4f4 !important;
                                position: absolute;
                                right: -10%;
                                top: 40%;

                                img {
                                    // position: absolute !important;
                                    // top: 0;
                                    // left: 0;
                                    // margin: auto;
                                    height: 28px !important;
                                    width: 28px !important;
                                }
                            }
                        }
                    }
                }
            }
            .basket_primary_action_container {
                height: 10%;
                position: absolute;
                width: 100%;
                bottom: 0;
                background: #f2f4f4;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                button {
                    padding: 0;
                    background: white;
                    border: 1px solid #e5e8e8;
                    img {
                        height: 18px;
                        width: 24px;
                    }
                }
                button:first-child,
                button:nth-child(2) {
                    width: 12%;
                    height: 45%;
                }
                button:last-child {
                    width: 60%;
                    height: 45%;
                    background: #34495e;
                    color: #f2f4f4;
                    font-weight: bold;
                    img {
                        width: 14px !important;
                        margin: 0px 10px 0px 0px;
                    }
                }
            }
        }
    }
}

.basket_offcanvas ~ .modal-backdrop {
    z-index: 1250 !important;
}

.basket_form_modal {
    z-index: 1300 !important;
    .modal-dialog {
        .modal-content {
            height: 90vh;
            border-radius: 0;
            width: 90%;
            margin: auto;
            .modal-header {
                background: #f2f4f4;
                color: #34495e;
                font-weight: 600;
                padding: 12px 20px;
                font-size: 17px;
                border: none;
                .btn-close {
                    opacity: 1;
                    background: transparent
                        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
                        center/0.7em auto no-repeat;
                }
            }
            .modal-body {
                background: white;
                padding: 12px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 10px;
                .form_field_wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    color: #34495e;
                    row-gap: 3px;
                    label {
                        font-size: 13px;
                        font-weight: 600;
                    }
                    input,
                    textarea,
                    select {
                        border: 1px solid #e5e8e8;
                        color: #34495e;
                        font-size: 12px;
                        height: 30px;
                    }
                    select {
                        background: #34495e;
                        color: #f2f4f4;
                    }
                    textarea {
                        height: 90px !important;
                    }
                    .input__container {
                        input {
                            border: 1px solid #e5e8e8;
                            color: #34495e;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 0px;
                        }
                    }
                    span {
                        display: none;
                    }
                    .downshift-dropdown {
                        padding: 0;
                        border: 1px solid #e5e8e8;
                        border-top: 0 !important;
                        font-size: 12px;
                    }
                    button {
                        width: 100%;
                        height: 30px;
                        background: #34495e;
                        color: #f2f4f4;
                        font-weight: bold;
                        margin: 10% auto;
                        // position: absolute;
                        // bottom: 0;
                        // left: 2%;
                        // right: 2%;
                    }
                }
            }
        }
    }
}

.basket_options {
    z-index: 1260 !important;
    .modal-dialog {
        // margin: 100% 15px;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        margin: 0;
        // height: 85vh;
        .modal-content {
            border-radius: 0;
            margin: 0;
            // height: 100%;
            .modal-header {
                .btn-close {
                    background: transparent
                        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
                        center/0.6em auto no-repeat;
                    // padding: 0;
                }
                border: none;
                padding: 8px 8px 0px 0px;
            }

            .modal-body {
                padding: 0 15px;
                .basket_option_container {
                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        li {
                            color: #34495e;
                            font-size: 14px;
                            font-weight: 700;
                            padding: 15px 0px;
                            border-bottom: 1px solid #d0d3d4 !important;
                        }
                        li:last-child {
                            border-bottom: none !important;
                        }
                    }
                    .accordion {
                        .accordion-item {
                            border-color: white;
                            background-color: #f2f4f5 !important;
                            border: white;
                            .accordion-header {
                                .accordion-button,
                                .accordion-button:focus {
                                    padding: 18px 0px;
                                    font-size: 14px;
                                    font-weight: 700;
                                    color: #34495e !important;
                                    outline: none !important;
                                    background: white !important;
                                    border: none !important ;
                                    box-shadow: none !important;
                                    border-bottom: 1px solid #d0d3d4 !important;
                                    // border-top: 1px solid black !important;
                                }
                                .accordion-button::after {
                                    content: "+";
                                    /* width: 12px; */
                                    /* height: 12px; */
                                    background-image: none;
                                    transition: none !important;
                                    transform: none !important;
                                    position: relative;
                                    color: #34495e;
                                    padding: 0px 5px;
                                    outline: none;
                                    background: #f2f4f4;
                                }
                                .accordion-button:not(.collapsed)::after {
                                    content: "-";
                                    padding: 0px 7px;
                                }
                            }
                            .accordion-body {
                                // height: 200px;
                                padding: 15px 0px 10px;
                                background-color: white !important;
                                display: flex;
                                flex-direction: column;
                                row-gap: 10px;
                                color: #34495e !important;

                                .basket__open__grouped {
                                    display: flex;
                                    flex-direction: column;
                                    row-gap: 6px;
                                    .all__basket__container {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: baseline;
                                        gap: 10px;
                                        label {
                                            width: 25%;
                                            font-size: 13px;
                                            font-weight: 600;
                                        }
                                        .open__input {
                                            width: 75%;
                                            display: flex;
                                            flex-direction: column;
                                            row-gap: 20px;
                                            align-items: center;
                                            .Select {
                                                width: 100%;
                                                height: 30px;
                                                .css-1s2u09g-control {
                                                    min-height: 30px;
                                                    font-size: 13px;
                                                }
                                                .css-26l3qy-menu {
                                                    font-size: 13px;
                                                }
                                            }
                                            button {
                                                width: auto;
                                                font-size: 13px;
                                                border: none;
                                                color: #f2f4f4 !important;
                                                background: #34495e;
                                                padding: 4px 8px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.basket_select {
    z-index: 1400 !important;
    .modal-dialog {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        height: 85%;
        overflow: hidden;
        .modal-content {
            height: 100%;
            border-radius: 0;
            .modal-header {
                padding: 0;
                border: none;
                .btn-close {
                    position: absolute;
                    top: 2%;
                    right: 4%;
                    background: transparent
                        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
                        center/0.7rem auto no-repeat;
                    z-index: 1;
                }
            }
            .modal-body {
                padding: 0px;
                height: 100%;
                .basket_select_header {
                    display: flex;
                    flex-direction: column;
                    color: #34495e !important;
                    gap: 8px;
                    background: #f2f4f4;
                    padding: 15px 20px;
                    h6 {
                        font-size: 15px;
                        font-weight: 700;
                        margin: 0;
                    }
                    span {
                        font-size: 12px;
                        font-weight: 500;
                    }
                    .search_container {
                        display: flex;
                        gap: 8px;
                        input {
                            border: 1px solid #d0d3d4 !important;
                            outline: none;
                            width: 80%;
                        }
                        button {
                            width: 20%;
                            border: none;
                            outline: none;
                            background: #34495e;
                            color: white;
                            font-size: 14px;
                        }
                    }
                }
                .basket_list_container {
                    overflow: auto;
                    color: #34495e !important;
                    height: 80%;

                    ul {
                        //  overflow: auto;
                        // height: 100%;
                        list-style-type: none;
                        padding: 10px 20px;
                        li {
                            font-size: 14px;
                            font-weight: 500;
                            padding: 10px 0px;
                            display: flex;
                            flex-direction: column;
                            label {
                                font-size: 15px;
                                font-weight: 700;
                            }
                            span {
                                font-weight: 500;
                                font-size: 12px;
                            }
                            border-bottom: 1px solid #d0d3d4 !important;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.choose-layout-modal {
    z-index: 1260 !important;
    overflow: hidden !important;
    .modal-dialog {
        height: 100%;
        // width: 50vw;
        margin: 0;
        // @media (max-width: 767px) {
        //     width: 100%;
        //     height: 100%;
        // }
        .modal-content {
            position: absolute;
            display: flex;
            bottom: 0;
            left: 0;
            right: 0;
            flex-direction: column;
            width: 100%;
            height: 90%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;
            outline: 0;
            .modal-header {
                background: #f2f4f4;
                color: #34495e;
                font-weight: 700;
                padding: 12px 20px;
                font-size: 17px;
                border: none;
                .btn-close {
                    opacity: 1;
                    background: transparent
                        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
                        center/0.7em auto no-repeat;
                }
                // @media (max-width: 767px) {
                //     font-size: 16px;
                //     font-weight: bold;
                // }
            }
            .modal-body {
                padding: 5px 20px;
                overflow: auto;
                h4 {
                    // text-align: center;
                    font-weight: bold;
                    font-size: 14px;
                    margin: 6px 0px;
                }
                .price__checkbox__wrapper {
                    // @media (max-width: 767px) {
                    //     padding-right: 17%;
                    // }
                    padding: 5px 0px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    h4 {
                        width: 100%;
                        color: #34495e;
                        font-size: 16px;
                    }
                    .price__toggle {
                        margin-top: 4px;
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        input[type="checkbox"] {
                            margin: 0px 6px 0px 10px;
                            width: 13px;
                            height: 13px;
                            // @media (max-width: 767px) {
                            //     width: auto;
                            //     height: auto;
                            // }
                        }
                        #no_price_check {
                            margin: 0px 6px 0px 0px !important;
                        }
                        label {
                            cursor: pointer;
                            margin: 0px 0px 2px 0px;
                            color: #808b96;
                            font-size: 15px;
                        }
                    }
                    // .price__toggle:nth-child(1) {
                    //     input[type="checkbox"],
                    //     #no_price_check {
                    //         margin: 0px 6px 0px 0px !important;
                    //     }
                    // }
                }

                .links__checkbox__wrapper,
                .gia__checkbox__wrapper {
                    // @media (max-width: 767px) {
                    //     padding-right: 17%;
                    // }
                    padding: 5px 0px;
                    color: #34495e;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    h4 {
                        width: 100%;
                        font-size: 16px;
                    }
                    // justify-content: center;
                    .links__toggle,
                    .gia__toggle {
                        // margin-top: 5px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 13px;

                        input[type="radio"] {
                            margin: 2px 6px 0px 10px;
                            width: 13px;
                            height: 13px;
                            // @media (max-width: 767px) {
                            //     width: auto;
                            //     height: auto;
                            // }
                        }
                        label {
                            margin: 0px;
                            cursor: pointer;
                            color: #808b96;
                            font-size: 15px;
                        }
                        #linksChoice1,
                        #linksChoice2,
                        #linksChoice3,
                        #giaChoice1,
                        #giaChoice2 {
                            margin: 2px 6px 0px 0px !important;
                        }
                    }
                }

                .layouts,
                .covers {
                    padding: 5px 0px;
                    // @media (max-width: 767px) {
                    //     margin-bottom: 10px;
                    // }
                    h4 {
                        font-weight: bold;
                        color: #34495e;
                        font-size: 16px;

                        // text-align: center;
                        // padding-left: 5%;
                    }
                    .layouts-wrapper,
                    .covers-wrapper {
                        list-style: none;
                        display: inline-flex;
                        align-items: flex-start;
                        // justify-content: space-around;
                        flex-wrap: wrap;
                        width: 100%;
                        row-gap: 15px;
                        column-gap: 15px;
                        .layout_option,
                        .cover_option {
                            width: 30%;
                            height: 150px;
                            font-size: 14px;
                            font-weight: bold;
                            padding: 10px;
                            cursor: pointer;
                            border: 1.5px solid #808b96 !important;
                            background-color: rgb(255, 254, 254);
                            color: #808b96;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-around;
                            // @media (max-width: 767px) {
                            //     font-size: 14px;
                            //     padding: 5px;
                            //     margin: 2px 10px;
                            //     text-align: center;
                            // }
                            img {
                                max-width: 80px;
                                min-height: 80px;
                                max-height: 80px;
                                // @media (max-width: 767px) {
                                //     max-width: 100px;
                                //     height: auto;
                                // }
                            }
                            span {
                                margin-top: 10px;
                                font-size: 11px;
                                font-size: 14px;
                                text-align: center;
                            }
                        }
                        .Active {
                            border: 1.5px solid #34495e !important;
                            background-color: #34495e;
                            color: rgb(255, 254, 254);
                        }
                    }
                }
                .action-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    button {
                        font-size: 14px;
                        margin: 2px 7px;
                        border: 1.5px solid #58585a;
                        padding: 5px 15px;
                        border-radius: 0px;
                        color: #ffffff;
                        background-color: #58585a;
                        outline: none;
                    }
                }
            }
        }
    }
}

.pdf_modal,
.email_modal {
    z-index: 1270 !important;
    overflow: hidden !important;

    .modal-dialog {
        height: 100%;
        margin: 0;
        .modal-content {
            position: absolute;
            display: flex;
            bottom: 0;
            left: 0;
            right: 0;
            flex-direction: column;
            width: 100%;
            height: 90%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            // border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;
            outline: 0;

            // @media (max-width: 767px) {
            //     width: 100%;
            //     height: 100%;
            //     // margin: 3px 0px;
            // }

            // @media (max-width: 767px) {
            //   width: 100%;
            //   height: 100%;
            // }

            .modal-header {
                background: #f2f4f4;
                color: #34495e;
                font-weight: 600;
                padding: 12px 20px;
                font-size: 17px;
                border: none;
                display: flex;
                flex-wrap: wrap;
                .btn-close {
                    opacity: 1;
                    background: transparent
                        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
                        center/0.7em auto no-repeat;
                    position: absolute;
                    top: 3%;
                    right: 2%;
                }
                h4 {
                    margin: 0;
                    font-size: 17px;
                    color: #34495e;
                }
                // .email_modal_header {
                //     display: flex !important;
                //     align-items: center !important;
                //     justify-content: flex-start !important;
                //     .action-buttons {
                //         margin-left: 68%;
                //     }
                // }
                .pdf_modal_header,
                .email_modal_header {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    gap: 5px;
                    // flex-wrap: wrap;
                    .lookbook_options {
                        width: 100%;
                        padding-top: 0px;
                        display: flex;
                        flex-wrap: wrap;
                        row-gap: 5px;
                        .item_No_checkbox_wrapper,
                        .price_label_checkbox_wrapper {
                            // @media (max-width: 767px) {
                            //     padding-right: 17%;
                            // }
                            padding: 0px 0px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            column-gap: 8px;
                            width: 50%;
                            // justify-content: center;
                            h4 {
                                font-size: 14px;
                                margin: 3px 0 0 0;
                                width: 100%;
                            }
                            .item_No_toggle,
                            .price_label_toggle {
                                margin-top: 3px;
                                display: flex;
                                align-items: center;
                                font-size: 13px;
                                column-gap: 4px;
                                input[type="radio"] {
                                    margin: 2px 0px 0px 0px;
                                    width: 12px;
                                    height: 12px;
                                    @media (max-width: 767px) {
                                        width: auto;
                                        height: auto;
                                    }
                                }
                                input[type="checkbox"] {
                                    margin: 3px 0px 0px;
                                    width: 12px;
                                    height: 12px;
                                    @media (max-width: 767px) {
                                        width: auto;
                                        height: auto;
                                    }
                                }

                                label {
                                    // margin-top: 3px;
                                    margin-bottom: 0px !important;
                                    cursor: pointer;
                                }
                            }
                        }
                        .include_stones_checkbox,
                        .include_location_checkbox {
                            padding: 0px 0px;
                            display: flex;
                            align-items: center;
                            column-gap: 4px;
                            width: 50%;
                            input[type="radio"]#stone_check {
                                margin-top: 0px;
                            }
                            label {
                                margin: 0;
                                cursor: pointer;
                                font-size: 12px;
                            }
                        }
                    }
                    .action-buttons {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        button.email,
                        button.clear,
                        button.back {
                            // @media (max-width: 767px) {
                            //     margin-left: 12px;
                            // }
                            font-size: 12px;
                            margin: 2px 5px;
                            border: 1.5px solid #34495e;
                            padding: 5px 15px;
                            border-radius: 0px;
                            color: #f2f4f4;
                            background-color: #34495e;
                            outline: none;
                            white-space: nowrap;
                        }
                        // button.clear,
                        // button.back {
                        //     // @media (max-width: 767px) {
                        //     //     margin-left: 12px;
                        //     // }
                        //     font-size: 12px;
                        //     margin: 2px 5px;
                        //     border: 1.5px solid #f2f4f4;
                        //     padding: 5px 15px;
                        //     border-radius: 0px;
                        //     color: #34495e;
                        //     background-color: #f2f4f4;
                        //     outline: none;
                        // }
                    }
                }
            }

            .css-42igfv {
                margin: 40% 45%;
            }
            div.tab-content > div.tab-pane > h5 {
                margin: 10px 10px 0px !important;
            }

            h4 {
                margin-left: 10px;
                font-weight: bold;
            }
            .modal-body {
                padding: 5px 20px;
                overflow: auto;
                .basket__input__wrapper {
                    padding: 5px 0px;
                    .basket__input {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 15px;
                        letter-spacing: 0.6px;
                        row-gap: 4px;
                        // @media (max-width: 767px) {
                        //     flex-direction: column;
                        // }
                        label {
                            width: 100%;
                            font-size: 14px;
                            font-weight: 700;
                            // color: $Dark_Titles_Color;
                            color: #34495e;

                            letter-spacing: 0.6px;
                            // @media (max-width: 767px) {
                            //     font-size: 13px;
                            //     width: 100%;
                            // }
                            display: flex;
                            p {
                                color: red !important;
                                margin: 2px 0px 0px 0px;
                            }
                        }

                        .react-multi-email {
                            padding: 2px 2px !important;
                            border: 1px solid #e5e8e8 !important;
                            border-radius: 0;
                            height: auto;
                            width: 100%;
                            // @media (max-width: 767px) {
                            //     font-size: 13px;
                            //     width: 100%;
                            // }
                            label {
                                width: auto;
                                border: 1px solid black;
                                padding: 5px 5px 5px 5px;
                                border-radius: 25px;
                                margin: 0 0 0 1px;
                                font-size: 14px;
                                color: #34495e;

                                span {
                                    padding: 1px 5px 2px 5px;
                                    // margin-top: 5px !important;
                                }
                            }
                            input {
                                margin-left: 3px;
                                font-size: 14px !important;
                                height: 28px;
                                color: #34495e;
                            }
                        }
                        > input {
                            width: 90%;
                            height: 35px;
                            margin-left: 6px;
                            border: 1px solid #e5e8e8 !important;
                            border-radius: 0;
                            color: #34495e;

                            @media (max-width: 767px) {
                                margin-left: 0px !important;
                                width: 100%;
                            }
                        }
                        input.focus-visible {
                            outline: none !important;
                        }
                        .quill {
                            width: 90%;
                            margin-left: 6px;
                            border: 1px solid #e5e8e8 !important;
                            border-radius: 0;
                            @media (max-width: 767px) {
                                margin-left: 0px;
                                width: 100%;
                            }

                            .ql-toolbar.ql-snow {
                                border-top: none !important;
                                border-left: none !important;
                                border-right: none !important;
                                @media (max-width: 767px) {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: center;
                                    flex-direction: row;
                                    // max-height: 200px !important;
                                    .ql-toolbar.ql-snow > span.ql-formats {
                                        display: flex;
                                        flex-wrap: wrap;
                                        flex-direction: row;
                                        align-items: flex-start;
                                    }
                                }
                            }

                            .ql-container.ql-snow {
                                border: none;
                                height: 210px;
                                overflow: auto;

                                .ql-editor.ql-blank,
                                .ql-editor.focus-visible .ql-editor {
                                    height: 100%;
                                    p {
                                        font-size: 12px;
                                        color: #34495e;
                                    }

                                    // p {
                                    //   height: 120px;
                                    // }
                                }
                            }
                        }
                    }
                }
                .email_modal_header {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    gap: 5px;
                    // flex-wrap: wrap;

                    .action-buttons {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        button.email,
                        button.clear,
                        button.back {
                            // @media (max-width: 767px) {
                            //     margin-left: 12px;
                            // }
                            font-size: 12px;
                            margin: 2px 5px;
                            border: 1.5px solid #34495e;
                            padding: 5px 15px;
                            border-radius: 0px;
                            color: #f2f4f4;
                            background-color: #34495e;
                            outline: none;
                            white-space: nowrap;
                        }
                        // button.clear,
                        // button.back {
                        //     // @media (max-width: 767px) {
                        //     //     margin-left: 12px;
                        //     // }
                        //     font-size: 12px;
                        //     margin: 2px 5px;
                        //     border: 1.5px solid #f2f4f4;
                        //     padding: 5px 15px;
                        //     border-radius: 0px;
                        //     color: #34495e;
                        //     background-color: #f2f4f4;
                        //     outline: none;
                        // }
                    }
                }

                hr.divider {
                    background-color: #58585a;
                    height: 2px;
                }

                .preview-header {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    h4 {
                        width: 80%;
                        margin: 0;
                        font-size: 17px;
                        color: #34495e;

                        // @media (max-width: 767px) {
                        //     width: 60%;
                        // }
                    }
                    button,
                    .csv-link {
                        font-size: 12px;
                        margin: 2px 5px;
                        border: 1.5px solid #34495e;
                        padding: 5px 15px;
                        border-radius: 0px;
                        color: #f2f4f4;
                        background-color: #34495e;
                        outline: none;
                        white-space: nowrap;
                    }
                }
                .csvData_container {
                    width: 100%;
                    overflow: auto;
                    height: 500px;
                    margin: 20px 0px 0px;
                }

                .layout_second_header_preview {
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    // position: absolute;
                    // top: 0%;
                    // right: 0px;
                    width: 100%;
                    padding: 20px;
                    font-family: Arial, Helvetica, sans-serif;
                    margin-top: 20px;
                }
                .layout_second_footer_preview {
                    border-top: solid rgb(184, 184, 184) 1px;
                    text-align: center;
                    font-weight: 500;
                    // position: absolute;
                    // bottom: 0.25in;
                    // right: 0px;
                    // left: 0px;
                    padding: 6px 0px 4px 0px;
                    margin: 20px 0.4in 0px 0.4in;
                    // width: 100%;
                    height: 5%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    font-size: 9px;
                    font-family: Arial, Helvetica, sans-serif;
                    // margin-bottom: 20px;

                    span:first-child {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-around;
                        .copyright {
                            font-size: 5px;
                        }
                    }
                    span:nth-child(2) {
                        width: 50%;
                        // margin-right: 10%;
                    }

                    .kwiat {
                        max-width: 70px;
                    }
                    .fred {
                        max-width: 230px !important;
                    }
                    .kwfl {
                        max-width: 130px !important;
                    }
                    .bridal {
                        max-width: 70px !important;
                    }
                    // img {
                    //   max-width: 130px !important;
                    //   // max-height: 120px !important;
                    // }
                    // left: 10px;
                }
            }
        }
        .modal-content > button {
            @media (max-width: 767px) {
                margin-left: 85%;
            }
            cursor: pointer;
            position: sticky;
            right: 10px;
            bottom: 35px;
            margin-left: 120%;
            width: 35px;
            height: 46px;
            border: 1.5px solid #d0d0d0 !important;
            background-color: #d0d0d0;
            /* border-radius: 80%; */
            display: grid;
            padding: 7px;
            img {
                margin: auto;
                height: 15px;
                width: 15px;
            }
        }
    }
}

.show-export-modal,
.show-diamond-export-modal {
    z-index: 1400 !important;
    .modal-dialog {
        height: 100%;
        margin: 0;
        // @media (max-width: 767px) {
        //     width: 100%;
        // }

        .modal-content {
            position: absolute;
            display: flex;
            bottom: 0;
            left: 0;
            right: 0;
            flex-direction: column;
            width: 100%;
            height: 43%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border-radius: 0;
            outline: 0;
            margin: 0;
            .modal-header {
                background: #f2f4f4;
                color: #34495e;
                font-weight: 600;
                padding: 12px 20px;
                font-size: 17px;
                border: none;
                display: flex;
                flex-wrap: wrap;
                .btn-close {
                    opacity: 1;
                    background: transparent
                        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
                        center/0.7em auto no-repeat;
                    position: absolute;
                    top: 6%;
                    right: 3%;
                }
                h4 {
                    margin: 0;
                    font-size: 17px;
                    color: #34495e;
                }
            }
            .modal-body {
                padding: 5px 20px;
                display: flex;
                flex-direction: column;
                /* gap: 52px; */
                justify-content: space-evenly;
            }

            .price__checkbox__wrapper {
                // @media (max-width: 767px) {
                //     padding-right: 17%;
                // }
                // padding-right: 7%;
                // margin-bottom: 3%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                row-gap: 10px;
                .price__toggle {
                    // margin-left: 50px;
                    display: flex;
                    align-items: center;
                    input[type="checkbox"] {
                        margin: 0 0 0px 10px;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #e5e8e8 !important;
                    }
                    label {
                        color: #34495e;
                        font-size: 13px;
                        font-weight: 700;
                    }
                }
            }
            .export-option {
                // padding: 10px 100px 20px 100px;
                // @media (max-width: 767px) {
                //     padding: 25px 5px;
                // }
                ul {
                    margin: auto;
                    list-style: none;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    // justify-items: center;
                    // align-content: center;
                    width: 100%;
                    padding: 0;
                    li,
                    button {
                        // margin: 2px 10px;
                        width: 160px;
                        font-size: 13px;
                        font-weight: 700;
                        padding: 10px 5px;
                        cursor: pointer;
                        border: 1.5px solid #34495e !important;
                        color: #f2f4f4;
                        text-align: center;
                        background-color: #34495e;
                        // @media (max-width: 767px) {
                        //     font-size: 13px;
                        //     font-weight: bold;
                        //     // white-space: nowrap;
                        //     text-align: center;
                        //     margin: 2px 10px;
                        //     padding: 5px;
                        // }
                    }
                }
            }
        }
    }
}

.show-diamond-export-modal {
    z-index: 100001 !important;
}

.export-jewelry-modal {
    z-index: 10001;
    .modal-dialog {
        margin: 0px;
        height: 100%;
        overflow: hidden;
        // @media (max-width: 767px) {
        //     width: 100%;
        // }
    }
    .modal-content {
        position: absolute;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        width: 100%;
        height: 90%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0;
        outline: 0;
        .modal-header {
            background: #f2f4f4;
            color: #34495e;
            font-weight: 600;
            padding: 12px 20px;
            font-size: 17px;
            border: none;
            display: flex;
            flex-wrap: wrap;
            .btn-close {
                opacity: 1;
                background: transparent
                    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
                    center/0.7em auto no-repeat;
                position: absolute;
                top: 2%;
                right: 2%;
            }
            h4 {
                margin: 0;
                font-size: 17px;
                color: #34495e;
            }
        }
        .modal-body {
            padding: 5px 20px;
            overflow: auto;
            .fields_checkbox_wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                width: 70%;
                margin: auto;
                h4 {
                    position: relative;
                    color: #34495e;
                    font-size: 15px;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    // @media (max-width: 767px) {
                    //     font-size: 15px;
                    //     font-weight: bold;
                    // }
                    input[type="checkbox"] {
                        margin: 0 0 0 20px;
                        width: 18px;
                        height: 18px;
                        // position: absolute;
                        border: 1px solid #e5e8e8 !important;

                        // bottom: 0;
                        // @media (max-width: 767px) {
                        //     // position: relative;
                        //     // margin-top: 10px !important;
                        //     width: 15px !important;
                        //     height: 15px !important;
                        // }
                    }
                }
                .field_toggle,
                .advanced_field_toggle {
                    // margin-left: 50px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin: 5px;
                    label {
                        width: 70%;
                        font-size: 13px;

                        color: #34495e;

                        // @media (max-width: 767px) {
                        //     width: 70%;
                        // }
                    }
                    input[type="checkbox"] {
                        margin: 0 0 0 10px;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #e5e8e8 !important;

                        // @media (max-width: 767px) {
                        //     // position: relative;
                        //     // margin-top: 10px !important;
                        //     width: 15px !important;
                        //     height: 15px !important;
                        // }
                    }
                    span {
                        position: absolute;
                        left: 100%;
                        font-size: 22px;
                        cursor: pointer;
                        color: #337ab7;
                        width: 5%;
                        text-align: center;
                        @media (max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }
                .advanced_field_toggle {
                    // opacity: 0;
                    display: none;
                }
                button {
                    font-size: 12px;
                    margin: 2px 5px;
                    border: 1.5px solid #34495e;
                    padding: 5px 15px;
                    border-radius: 0px;
                    color: #f2f4f4;
                    background-color: #34495e;
                    outline: none;
                    white-space: nowrap;
                }
            }
        }
    }
}

.iframeModal {
    z-index: 10000 !important;
    cursor: pointer;
    .modal-dialog {
        width: 95vw;
        // @media (max-width: 767px) {
        //     width: 100%;
        //     max-height: 90%;
        // }
    }
    .modal-body {
        padding: 0;
    }
    iframe {
        width: 100%;
        min-height: 85vh;
        // @media (max-width: 767px) {
        //     min-height: 75vh;
        // }
    }
}

.stock-popup-modal {
    z-index: 1200 !important;
    // font-family: "Proxima Nova Regular";
    // background: none !important;
    // padding: 0 !important;
    // .product-details_line-break {
    //     font-size: 16px;
    // }
    // .product-details-view-label {
    //     font-size: 14px !important;
    //     line-height: 24px !important;
    //     color: #58585a !important;
    // }
    // .product-details-label {
    //     color: #58585a !important;
    // }
    // .highlight-text {
    //     color: green !important;
    // }
    // .border-bottom {
    //     border-bottom: 1px dotted black;
    //     margin-bottom: 13px;
    // }
    // .product-details_lcol {
    //     margin-right: 50px;
    //     @media (max-width: 767px) {
    //         margin-right: 0px;
    //     }
    // }
    // .product-details_mcol {
    //     @media (max-width: 767px) {
    //         margin-left: 8px;
    //     }
    // }
    // .product-info_background {
    //     padding-top: 15px;
    //     padding-left: 15px;
    // }
    // .see-more {
    //     margin-top: 5px;
    //     text-align: center;
    //     .see-more-btn {
    //         background: #8a8a8a;
    //         background-image: none;
    //         text-shadow: none;
    //         color: #fff;
    //         width: 50%;
    //         border-radius: 0;
    //     }
    // }
    .modal-dialog {
        max-width: 50%;
        @media (max-width: 767px) {
            min-width: 95%;
            max-height: 90%;
        }
        .modal-content {
            border-radius: 0;
            // border: 2px solid #8a8a8a;
            .modal-header-con {
                text-align: center;
                .modal-title {
                    color: #34495e;
                }
                border-bottom: 0;
            }
            .modal-body {
                .product-details_line-break {
                    font-size: 14px;
                }
                .product-details-view-label {
                    font-size: 14px !important;
                    line-height: 24px !important;
                    color: #58585a !important;
                }
                .product-details-label {
                    color: #58585a !important;
                }
                .highlight-text {
                    color: green !important;
                }
                .border-bottom {
                    border-bottom: 1px dotted black;
                    margin-bottom: 13px;
                }
                .product-details_lcol {
                    margin-right: 50px;
                    @media (max-width: 767px) {
                        margin-right: 0px;
                    }
                }
                .product-details_mcol {
                    @media (max-width: 767px) {
                        margin-left: 8px;
                    }
                }
                .product-info_background {
                    padding-top: 15px;
                    padding-left: 15px;
                }
                .see-more {
                    margin-top: 5px;
                    text-align: center;
                    .see-more-btn {
                        background: #8a8a8a;
                        background-image: none;
                        text-shadow: none;
                        color: #fff;
                        width: 50%;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

.product {
    width: 100%;
    display: flex;
    margin-bottom: 4px;
    border: 1px solid #d2d4d7;
    background: $Background_for_Rows_Color;
    padding: 12px 0 8px;
    font-size: 0.75em;
    @media (max-width: 767px) {
        font-size: 0.65em;
    }
    .index-share {
        min-height: 100%;
        width: 2%;
        margin: 0 5px;
        @media (max-width: 767px) {
            width: 4%;
        }
        .product-index_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%;
            @media (max-width: 767px) {
                width: auto;
            }
            .mobile__basket__add-section {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 10px;
                .es__basket-icon,
                .mail__share-icon,
                .print__share-icon {
                    width: 100%;
                }
            }
        }
        .serial-hovered {
            display: none !important;
            @media (max-width: 767px) {
                display: flex;
            }
        }
        .es__product__hovered {
            width: 100%;
            padding: 2px !important;
            margin: 0;
            display: none;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: space-around;
            cursor: pointer;
            .es__basket-icon {
                width: 18px;
            }
            .share-icons {
                width: 15px;
            }
        }
        .share-hovered {
            display: flex;
            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    .product-details_lcol {
        text-align: left;
    }
    .product-details-label {
        margin: 3px 0 8px 0;
        color: #3d4148;
        a {
            color: #337ab7;
        }
        a:hover {
            color: #23527c;
        }
        span {
            margin-top: 2px;
            line-height: 10px;
        }
    }
    .product-customer-details-label {
        margin: 3px 0 0 0;
        color: $Dark_Red_Color;
        line-height: 7px;
    }

    .product-info_box_heading {
        padding: 5px;
        margin: 0;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        text-align: center;
        span {
            background: $Background_for_Rows_Color;
            margin-left: 8px;
        }
    }
    .stock-modal-info_box_heading {
        span {
            background: #fff !important;
            font-size: 16px;
        }
    }
    .es__isfl {
        position: relative;
        height: 17px;
        margin-left: 0 !important;
        padding: 0 !important;
        bottom: 3px;
        content: url("https://cdn.kwiat.com/apps/kwiat-elastic-search/test-upgrade/icons/FL-DarkGrey.svg");
    }
    .es__istiara {
        position: relative;
        width: 20px;
        margin: 0px 2px !important;
        padding: 0 !important;
        bottom: 2px;
        content: url(https://svgshare.com/i/G9L.svg);
    }
    .product-stylenumber_heading {
        padding: 5px;
        margin: 0;
        position: absolute;
        top: -10px;
        display: flex;
        left: 0;
        right: 0;
        text-align: left;
        span {
            background: $Background_for_Rows_Color;
            padding: 1px 2px;
            margin-left: 8px;
        }
        img {
            background-color: white;
            cursor: pointer;
        }
    }
    .loose-results {
        line-height: 11px !important;
    }
    .additional-height {
        line-height: 15px !important;
    }

    .product-info_background {
        line-height: 11px;
        padding: 4px 10px 0px 10px;
        .product-description {
            font-size: 12px;
            font-style: italic;
            margin-top: 2px;
            margin-bottom: 5px;
            display: flex;
            column-gap: 8px;
            span:nth-child(2) {
                color: #337ab7;
                font-size: 12px;
                cursor: pointer;
                font-style: normal;
            }
        }
    }

    .product-image_background {
        padding-top: 1px;
    }
    .product-d-flex-container {
        display: flex !important;
        width: 88%;
        @media (max-width: 767px) {
            width: 65%;
        }
        & > div {
            padding-left: 8px;
        }
        @media (max-width: 767px) {
            .mobile-view {
                display: none;
            }
        }
        .holding-details {
            width: 25%;
            padding-right: 8px;
            @media (min-width: 768px) and (max-width: 1024px) {
                width: 20%;
            }
            .entered-by,
            .customer-name {
                word-break: break-word;
            }
            .holding-description {
                .holding-con {
                    word-break: break-all;
                }
            }
            .is_hold_sold {
                color: red;
            }
            .product-info_background {
                padding: 0px 10px 0px 10px;
                line-height: 14px;
                display: flex;
                flex-direction: column;
                // align-items: center;
                gap: 5px;
                @media (min-width: 768px) and (max-width: 1024px) {
                    .responsive-hold-notes {
                        display: table-caption;
                    }
                }
                .product-details-label {
                    margin: 0;
                }
            }
        }
        .product-details {
            width: 50%;
            @media (max-width: 767px) {
                width: 100%;
                padding-right: 7px;
            }
            .product-info_background {
                padding: 0px 10px 0px 10px;
                .flex-con {
                    display: flex;
                }
                .product-description-con {
                    word-break: break-word;
                }
                .product-description_info {
                    word-break: break-word;
                    cursor: auto;
                }
                .product-details_info {
                    font-family: "Proxima Nova Semibold";
                }
                .pdf-icon {
                    height: 12px;
                    width: auto;
                    margin-left: 5px;
                    margin-top: -3px;
                }
                .cursor-pointer {
                    cursor: pointer;
                }
                .cursor-none {
                    cursor: auto;
                }
            }
        }
        .product-status_details,
        .product-sold_details,
        .product-pricing_details {
            width: 14%;
            @media (min-width: 768px) and (max-width: 1024px) {
                width: 17%;
            }
            .product-status-con {
                .product-details-label {
                    div {
                        line-height: 11px;
                    }
                }
                .status,
                .location {
                    word-break: break-all;
                }
            }
            .product-info_background {
                padding: 3px 10px 0px 10px;
            }
            .product-info_box {
                padding-left: 8px;
            }

            section {
                line-height: 7px !important;
                .product-details_lcol {
                    line-height: 7px;
                }
                .product-details_mcol {
                    .product-details-label {
                        position: relative;
                        top: -2px;
                    }
                }
            }
            section:last-child {
                padding-top: 0px;
                .product-details-label {
                    margin-top: 0px;
                }
            }
        }
    }
    .product-info_box {
        padding: 10px 2px 2px 2px;
        border: 1px solid #e3e3e3;
        height: 100%;
        background: #fff;
    }
    .product-image_box {
        border: 1px solid #e3e3e3;
        height: 100%;
        background: #fff;
        .web-image-icon {
            width: 23px;
            float: right;
            cursor: pointer;
        }
    }
    // .product-index_container {
    //   display: flex;
    //   align-items: center;
    //   vertical-align: middle;
    //   max-width: 110px;
    // }

    .product-image_container {
        background: #fff;
        width: 9%;
        @media (max-width: 767px) {
            width: 30%;
        }
        .not-clickable {
            cursor: default;
        }

        .product-image {
            top: 14%;
            left: 0;
            right: 0;
            margin-top: 6px !important;
            margin-left: 4px !important;
            margin-right: 4px;
            margin-bottom: 6px;
            text-align: center;
            .result-images {
                max-width: 100px !important;
                max-height: 100px !important;
            }
        }
    }
    .product-details,
    .holding-details,
    .product-ring-detail,
    .product-sold_details,
    .product-stock_details {
        section {
            display: flex;
            justify-content: space-around;
            & > div {
                flex: 1 1 0;
            }
        }
    }

    .product-dark_title_color {
        color: $Dark_Titles_Color;
        font-family: "Proxima Nova Semibold";
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
    }
    .product-light_title_color {
        color: #34495e;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .product-details-info_label {
        font-size: 9px;
        color: $Small_Light_Grey_Text_Color;
        opacity: 0.5;
        margin-left: 5px;
    }
    .product-details-view-label {
        font-size: 10px;
        color: $Small_Light_Grey_Text_Color;
        opacity: 0.5 !important;
    }
    .product-details_line-break {
        display: block;
        // line-height: 7px;
        // padding-bottom: 2px;
    }
    .product-status {
        position: absolute;
        bottom: 0px;
        width: 100%;
        text-align: center;
        left: 0;
        padding: 2px 5px;
        &.mounted {
            background: #e3e3e3;
            color: #3d4148;
            text-transform: uppercase;
        }
        &.sold {
            background: $Light_Blue_Color;
            color: #3d4148;
            text-transform: uppercase;
            cursor: pointer;
        }
        &.adjusted {
            background: $Light_Blue_Color;
            color: #3d4148;
            text-transform: uppercase;
            cursor: pointer;
        }
        &.kwiat {
            background: $Light_Blue_Color;
            color: $Dark_Red_Color;
            text-transform: uppercase;
        }
        &.RTV {
            background: $Light_Red_Color;
            color: #3d4148;
            text-transform: uppercase;
        }
        &.in-production {
            background: #e3e3e3;
            color: #aa0707;
            text-transform: uppercase;
        }
        &.semimount {
            background: #e3e3e3;
            color: #aa0707;
            text-transform: uppercase;
        }
        &.ponumber {
            background: #b6d2f2;
            color: #3d4148;
            text-transform: uppercase;
        }
    }
    .es__mobile-product {
        display: none;
        width: 100%;
        @media (max-width: 767px) {
            display: block;
            text-align: right;
        }
        table {
            width: 100%;
            tbody {
                line-height: 21px;
                td {
                    padding-left: 3px;
                    font-weight: 700;
                    width: 50%;
                    text-align: left;
                    .es__mobile-product-details-table__label {
                        font-size: 0.75em;
                        color: #a0a0a0;
                        margin-left: 6px;
                        font-weight: 100;
                    }
                    .pdf-icon {
                        height: 13px;
                        margin: -1px 0px 0px 7px;
                    }
                    button {
                        width: 22px;
                        padding: 0;
                        background-color: #f9f9f9;
                        margin-bottom: 3px;
                        border-radius: 0px;
                        float: right;
                        margin-right: 6px;
                        border: #d6e0e8 1px solid;
                        box-shadow: none;
                        background-image: none;
                    }
                }
                .gia-pdf-data {
                    > div {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.tooltip {
    // position: relative;
    z-index: 10000 !important;
}

.Toastify__toast-container {
    z-index: 10021 !important;
}
.css-1mnns6r {
    position: relative;
}
.Toastify__toast {
    min-height: 54px !important;
}
.Toastify__toast--default {
    background: #cecece !important;
    color: #3c3c3c !important;
}
